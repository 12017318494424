<template>
  <v-menu offset-x left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small icon v-bind="attrs" v-on="on"
        ><v-icon>mdi-dots-vertical</v-icon></v-btn
      >
    </template>
    <v-list dense>
      <v-list-item @click="modificarDatos()">
        <v-list-item-title>Modificar dependencia</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "dependenciaMenu",
  props: ["dependencias"],
  methods: {
    modificarDatos() {
      const emitData = {
        idDependencia: this.dependencias,
      };

      this.$emit("modificarDatos", emitData);
    },
  },
};
</script>

<style>
.avatar-item {
  margin-right: 15px !important;
}
</style>