import { render, staticRenderFns } from "./dependenciaMenu.vue?vue&type=template&id=8976546e&"
import script from "./dependenciaMenu.vue?vue&type=script&lang=js&"
export * from "./dependenciaMenu.vue?vue&type=script&lang=js&"
import style0 from "./dependenciaMenu.vue?vue&type=style&index=0&id=8976546e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports