<template>
  <v-container >
    <v-card outlined>
      <v-toolbar dense flat
        >Dependencias
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="agregarDependencia = true"><v-icon>mdi-plus-circle</v-icon></v-btn>
      </v-toolbar>
      <v-divider class="mx-3" ></v-divider>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :search="search"
        :items="dependenciasList"
        class="mx-3"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [10, 20, 30],
          itemsPerPageAllText: 'Todos',
          itemsPerPageText: 'Elementos por página',
        }"
      >

      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, index) in items" :key="`item-${index}`" >
            <td width="80px">{{ item.numero }}</td>
            <td>{{ item.nombre }}</td>
            <td width="20px">
              <dependenciaMenuComponent
                :dependencias="item._id"
                @modificarDatos="modificarDatosAction"
              />
            </td>
          </tr>
        </tbody>
      </template>
      </v-data-table>
    </v-card>

    <agregarDependenciaComponent 
     v-if="agregarDependencia"
     :mostrar="agregarDependencia"
     @cancelar="agregarDependencia = false"
     @dependenciaAgregada="dependenciaAgregada"
    />
  
    <modificarDependenciaComponent
     v-if="modificarDatos.mostrar"
     :mostrar="modificarDatos.mostrar"
     :idDependencia="modificarDatos.idDependencia"
     :nombre="modificarDatos.nombre"
     @cancelar="modificarDatos.mostrar = false"
     @dependenciaModificada="dependenciaModificada"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getDependenciasService } from "./dependencias.service";
import agregarDependenciaComponent from "./agregarDependencia.vue";
import dependenciaMenuComponent from "./dependenciaMenu.vue";
import modificarDependenciaComponent from "./modificarDependencia.vue";



export default {
  name: "dependenciasView",
  components: { 
    agregarDependenciaComponent,
    dependenciaMenuComponent,
    modificarDependenciaComponent,
  },
  computed: {
    ...mapGetters(["userAdmin",]),
    dependenciasList() {
      const dependencias = [];
      let contador = 1;
      for (const dependencia of this.dependencias) {
        const nuevaDependencia = { numero: contador++, ...dependencia };
        dependencias.push(nuevaDependencia);
      }
      return dependencias;
    },
  },
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        {
          text: "#",
          align: "start",
          value: "numero",
        },
        { text: "Nombre", value: "nombre", align: "start" },
        { text: " ", value: " ", sortable: false },
      ],
      dependencias: [],
      agregarDependencia: false,
      modificarDatos: {
        mostrar: false,
        idDependencia: null,
        nombre: null,
      },
    }
  },
  mounted() {
    if (!this.userAdmin) this.$router.push("/sesiones");
    this.cargarDependencias();
  },
  methods: {
    async cargarDependencias() {
      try {
        this.loading = true;
        const serverResponse = await getDependenciasService();
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$notificarError(serverResponse.mensaje);
        }
        else {
          this.dependencias = serverResponse.dependencias;
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    dependenciaAgregada(data){
      this.agregarDependencia = false;
      this.dependencias.push(data.dependencia);
    },
    modificarDatosAction(data){
      const dependencia = this.dependencias.find((x) => x._id == data.idDependencia);
      this.modificarDatos.idDependencia = data.idDependencia;
      this.modificarDatos.nombre = dependencia.nombre;
      this.modificarDatos.mostrar = true;
    },
    dependenciaModificada(data){
      const index = this.dependencias.findIndex((x) => x._id == data.idDependencia);
      this.dependencias[index].nombre = data.nombre;
      this.modificarDatos.idDependencia = null;
      this.modificarDatos.nombre = null;
      this.modificarDatos.mostrar = false;
    }
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}


</style>
