<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
      <v-card>
          <v-toolbar color="primary" dark dense flat
            ><h3>Agregar dependencia</h3></v-toolbar
          >
          <v-container>
              <v-row no-gutters>
                  <v-col cols="12">
                      <v-text-field
                          v-model="nombre"
                          name="DP-nombre"
                          label="Nombre"
                          type="text"
                          :disabled="loading"
                          color="blue-grey"
                          outlined
                          dense
                          :error-messages="nombreErrors"
                          @input="validarNombre"
                          @blur="validarNombre"
                      >
                      </v-text-field>
                  </v-col>
              </v-row>
          </v-container>
          <v-divider class="mx-3"></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" small outlined @click="$emit('cancelar')"
                >Cancelar</v-btn
              >
              <v-btn
                  color="primary"
                  small
                  :class="{ 'disable-events': blockAceptar }"
                  @click="agregarDependencia()"
                  >Agregar</v-btn
              >
          </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

import { agregarDependenciaService } from "./dependencias.service";

export default {
  name: "agregarDependencia",
  props: ['mostrar'],
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["userAdmin"]),
    blockAceptar(){
      if (this.loading) return true;
      if (this.nombreErrors.length > 0) return true;
      if (this.nombre.length == 0) return true;
      return false;
    },
  },
  validations: {
    nombre: {required, minLength: minLength(5) },
  },
  data(){
    return {
      loading: false,
      nombre: "",
      nombreErrors:[],
    };
  },
  methods: {
    validarNombre() {
      this.$v.nombre.$touch();
      this.nombreErrors = [];
      !this.$v.nombre.required && this.nombreErrors.push("Campo requerido.");
      !this.$v.nombre.minLength && this.nombreErrors.push("Mínimo 5 caracteres.");
    },
    async agregarDependencia(){
      try {
        this.loading = true;
        const sendData = { 
          nombre: this.nombre
        };

        const serverResponse = await agregarDependenciaService(sendData);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if(!serverResponse.ok){
          if (serverResponse.nombreError)
            this.nombreErrors.push(serverResponse.mensaje);
          else this.$notificarError(serverResponse.mensaje);
        } else {
          const emitData = { dependencia: serverResponse.dependencia };
          this.$emit("dependenciaAgregada", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};


</script>
